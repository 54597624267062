import { lazyImport } from '@/utils/lazyImport';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';

const { Home } = lazyImport(() => import('@/features/home'), 'Home');
const { PasswordReset } = lazyImport(
  () => import('@/features/auth'),
  'PasswordReset',
);
// const { ResetPassword } = lazyImport(
//   () => import('@/features/resetPassword'),
//   'ResetPassword',
// );
// const { Terms } = lazyImport(() => import('@/features/misc'), 'Terms');
import { Terms } from '@/features/misc';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const auth = useSelector((state) => state.control.auth);

  const commonRoutes = [
    { path: '/password/reset/', element: <PasswordReset /> },
    { path: '/terms', element: <Terms /> },
    { path: '/', element: <Home /> },
    // { path: '*', element: <Navigate to="." /> },
  ];

  const routes = auth.email ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
