import React from 'react';

export const ShowcaseSection = React.memo(
  ({ title, sectionData, tableComponent, tableProps }) => {
    let dataType = sectionData?.type;
    let content;

    if (dataType === 'section') {
      content = sectionData.data.map((section, idx) => {
        return (
          <ShowcaseSection
            key={`${section.title}-${idx}`}
            title={section.title}
            sectionData={section}
            tableComponent={tableComponent}
            tableProps={tableProps}
          />
        );
      });
    } else {
      if (tableComponent) {
        content = React.cloneElement(tableComponent, {
          tableData: sectionData,
          ...tableProps,
        });
      }
    }

    return (
      <div className="mb-2 p-3 border">
        <div className="text-2xl bold text-slate-600 mb-4" id={title}>
          {title}
        </div>
        {content}
      </div>
    );
  },
);

ShowcaseSection.displayName = 'ShowcaseSection';
